<template>
  <div class="main"
       ref="main">
    <div class="topbar">
      <Tabs v-model="currentTabIndex"
            class="tabs">
        <TabPane v-for="item in tabArr"
                 :key="item.name"
                 :label="item.name"></TabPane>
      </Tabs>
      <div class="topbar-search">
        <div class="flex a-center j-between">
          <div class="flex a-center">
            <!-- <Input v-model="systemName"
                   placeholder="系统名称"
                   class="m-r-10"
                   style="width:160px" /> -->
          </div>
          <div class="flex">
            <Button class="m-l-10"
                    @click.stop="onClickRefresh">
              <Icon custom="i-icon icon-shuaxin"
                    size="16"></Icon>
              刷新
            </Button>
            <Button type="primary"
                    class="m-l-10"
                    @click.stop="onClickSearch">
              <Icon custom="i-icon icon-sousuo"
                    size="16"></Icon>
              查询
            </Button>
            <Button class="m-l-10"
                    @click.stop="onClickReset">
              <Icon custom="i-icon icon-shuaxin"
                    size="16"></Icon>
              重置
            </Button>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="flex a-center j-between">
        <template>
          <div class="flex">
            <!-- <Button type="primary"
                    class="m-r-10"
                    @click.stop="systemConfigVisible = true">
              <Icon custom="i-icon icon-tianjia1"
                    size="16"></Icon>
              添加
            </Button> -->
          </div>
        </template>
      </div>
      <div class="flex">
        <div class="table">
          <Table ref="table"
                 :height="tableHeight"
                 :columns="columns"
                 :data="data"
                 border
                 stripe
                 :loading="tableLoading"
                 @on-selection-change="onChangeSelect">
            <template slot-scope="{ row }"
                      slot="operation">
              <!-- <Button type="text"
                      style="color:#25bb96;padding:0 5px"
                      @click.stop="onClickEditSystemConfig(row)">修改</Button> -->
            </template>
          </Table>
          <Page class="m-t-10 m-l-10"
                :total="totalPage"
                :current="pageNum"
                :page-size="pageSize"
                show-total
                show-sizer
                show-elevator
                @on-change="onPageChange"
                @on-page-size-change="onPageSizeChange" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  components: {

  },
  data () {
    return {
      currentTabIndex: 0,
      tabArr: [
        {
          name: '设备监控'
        }
      ],
      systemName: '',
      totalPage: 0,
      pageNum: 1,
      pageSize: 20,
      tableHeight: 0,
      tableLoading: false,
      columns: [
        {
          title: '序号',
          width: 45,
          align: 'center',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '设备类型名称',
          key: 'equipTypeName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '绑定对象名称',
          key: 'bindobjname',
          tooltip: true,
          align: 'center'
        },
        {
          title: '设备编码',
          key: 'equipcode',
          tooltip: true,
          align: 'center'
        },
        {
          title: '通道',
          key: 'channel',
          tooltip: true,
          align: 'center'
        },
        {
          title: '视频直播状态',
          key: 'videoLiveStatus',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            if (row.videoLiveStatus == '1') {
              return h('span', {
                style: {
                  color: '#25bb96',
                }
              }, '正常')
            }
            return h('span', {
              style: {
                color: '#ff0000',
              }
            }, '异常')
          }
        },
        {
          title: '视频直播回放状态',
          key: 'videoPlaybackStatus',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            if (row.videoPlaybackStatus == '1') {
              return h('span', {
                style: {
                  color: '#25bb96',
                }
              }, '正常')
            }
            return h('span', {
              style: {
                color: '#ff0000',
              }
            }, '异常')
          }
        },
        {
          title: '视频心跳状态',
          key: 'videoHeartBit',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            if (row.videoHeartBit == '1') {
              return h('span', {
                style: {
                  color: '#25bb96',
                }
              }, '正常')
            }
            return h('span', {
              style: {
                color: '#ff0000',
              }
            }, '异常')
          }
        },
        {
          title: '油杆状态',
          key: 'oilStatus',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            if (row.oilStatus == '1') {
              return h('span', {
                style: {
                  color: '#25bb96',
                }
              }, '正常')
            }
            return h('span', {
              style: {
                color: '#ff0000',
              }
            }, '异常')
          }
        },

      ],
      data: [],
      selectArr: [],
      // 后台系统配置
      equipmentMonitorVisible: false,
      equipmentMonitorId: '',
      drawerWidth: 0,
    };
  },
  mounted () {
    this.init()
  },
  destroyed () {
    window.onresize = null
  },
  methods: {
    init () {
      this.tableHeight = window.innerHeight - 350
      window.onresize = () => {
        this.tableHeight = window.innerHeight - 350
      }
      this.drawerWidth = this.$refs.main.offsetWidth
      this.getList()
    },
    onChangeSelect (selection) {
      this.selectArr = selection.map(item => item.id)
    },
    // 页数改变
    onPageChange (event) {
      this.pageNum = event
      this.getList()
    },
    // 每页条数改变
    onPageSizeChange (event) {
      this.pageSize = event
      this.getList()
    },
    // 刷新
    onClickRefresh () {
      let params = {
        page: this.pageNum,
        size: this.pageSize,
      }
      this.$http.refreshEquipmentMonitorList().then(res => {
        this.tableLoading = false
        if (res.code == 200) {
          this.totalPage = res.result.total
          this.data = res.result.list
        }
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 查询
    onClickSearch () {
      this.pageNum = 1
      this.getList()
    },
    // 点击重置
    onClickReset () {
      this.resetSearchData()
      this.onClickSearch()
    },
    // 重置搜索条件
    resetSearchData () {
      this.systemName = ''
    },
    getList () {
      switch (this.currentTabIndex) {
        case 0:
          this.getEquipmentMonitorList();
          break;
        default:
          break;
      }
    },
    getEquipmentMonitorList () {
      let params = {
        page: this.pageNum,
        size: this.pageSize,
      }
      this.$http.getEquipmentMonitorList(params).then(res => {
        // console.log(res);
        this.tableLoading = false
        if (res.code == 200) {
          this.totalPage = res.result.total
          this.data = res.result.list
        }
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 修改
    onClickEditSystemConfig (row) {
      this.systemConfigId = row.sysId
      this.systemConfigVisible = true
    },
  },
}
</script>

<style lang='scss' scoped>
@import "@/scss/iviewCssReset/adminTabs.scss";

.main {
  width: 100%;
  height: 100%;
  padding: 16px;
  background-color: #f0f2f5;

  .topbar {
    background-color: #fff;

    .topbar-search {
      padding: 16px;
    }
  }

  .content {
    padding: 16px;
    background-color: #fff;
    margin-top: 16px;

    .tree {
      margin-top: 16px;
      width: 230px;
      margin-right: 16px;
      overflow-y: auto;
      flex-shrink: 0;
      border: 1px solid #e8eaec;
    }

    .table {
      margin-top: 16px;
      flex: 1;
    }
  }
}
</style>
